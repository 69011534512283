.widget {
    border: 1px solid #ccc;
    

    &__body {
        height: 350px;
        overflow: auto;
    }

    &__title {
        width: 100%;
        height: 50px;
        background-color: #428bca;
        line-height: 50px;
        color: white;
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        padding-left: 4vw;

        .daysSelector {
            position: absolute;
            right: 30px;
            top: 0;
            font-size: 12px;
        }
    }

    .widgetChart {
        height: 200px;
    }

    .table {
        tr {
            .cell-red {
                color: #e31b23;
            }

            .cell-green {
                color: green;
            }

            &:hover {
                td {
                    background-color: #C8CBCF;
                    cursor: pointer;
                }
            }
        }
    }

    .BaseTable {
        .BaseTable__header {
            background-color: white;
            
            .BaseTable__header-row {
                background-color: white;
            }
        }

        .BaseTable__row {
            &:nth-of-type(odd) {
                background-color: rgba(0,0,0,.05);
            }

            .BaseTable__row-cell-text {
                .red {
                    color: #e31b23;
                }

                .green {
                    color: green;
                }
            }

            &.active {
                background-color: rgba(0,0,0,.1);
            }

        }

        .currencyCell {
            padding-left: 10px;
        }
    }

    .footer {
        border-top: 1px solid #ccc;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        padding-right: 10px;

        a {
            padding-left: 10px;
        }

        span {
            float: right;
        }
    }

    &-with-footer {
        .widget__body {
            height: 325px;
        }
    }
}

.TickerWidget {
    overflow: hidden;
    white-space: nowrap;
    background-color: #002147;
    color: white;
    padding: 10px 0px;
    cursor: default;

    &__row {
        display: inline-block;
        position: relative;
    }

    .Ticker {
        display: inline-block;
        margin-right: 15px;

        &__value {
            font-weight: bold;
        }
    }
}

.LiveRateWidget {
    table {
        th {
            font-size: 14px;
            padding: 8px;
        }

        td {
            font-size: 11px;
            padding: 6px;
        }
    }
}

.handle {
  flex: none;
  width: 7.5px;
  height: 100%;
  position: absolute;

  &::before {
    content: '';
    border-left: 4px dotted #ccc;
    display: block;
    height: 16px;
    margin: 6px 3px;
  }

  &:hover::before {
    border-color: #888;
  }
}