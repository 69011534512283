html, body {
  overflow: hidden;
}

@media (max-width: 767px) {
  
    html, body {
        overflow-y: visible;
        overflow-x: hidden;
    }
    
}

.App {
    overflow: visible;
  }